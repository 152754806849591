import { createSelector } from "reselect";

const selectCompany = (state) => state.company;

export const selectCurrentUserCompany = createSelector(
	[selectCompany],
	(company) => company.currentUserCompany
);

export const selectCompanyError = createSelector(
	[selectCompany],
	(company) => company.error
);

export const selectCompanyLoading = createSelector(
	[selectCompany],
	(company) => company.loading
);

export const selectAllCompanies = createSelector(
	[selectCompany],
	(company) => company.allCompanies
);