import CompanyActionTypes from "./company.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	currentUserCompany: null,
	allCompanies: [],
};

const companyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CompanyActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case CompanyActionTypes.FETCH_ALL_COMPANIES_START:
		case CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_START:
		case CompanyActionTypes.UPDATE_COMPANY_CODE_START:
		case CompanyActionTypes.UPDATE_COMPANY_START:
			return {
				...state,
				loading: true,
			};
		case CompanyActionTypes.FETCH_ALL_COMPANIES_FAILURE:
		case CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_FAILURE:
		case CompanyActionTypes.UPDATE_COMPANY_CODE_FAILURE:
		case CompanyActionTypes.UPDATE_COMPANY_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case CompanyActionTypes.UPDATE_COMPANY_CODE_SUCCESS: 
		case CompanyActionTypes.UPDATE_COMPANY_SUCCESS: {
			return {
				...state,
				loading: false,
			};
		}
		case CompanyActionTypes.FETCH_ALL_COMPANIES_SUCCESS: {
			return {
				...state,
				allCompanies: action.payload,
				loading: false,
			};
		}
		case CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_SUCCESS: {
			return {
				...state,
				currentUserCompany: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default companyReducer;
