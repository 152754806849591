import EstateActionTypes from "./estate.types";

export const fetchAllEstatesStart = () => ({
	type: EstateActionTypes.FETCH_ALL_ESTATES_START,
});

export const fetchAllEstatesSuccess = (allEstates) => ({
	type: EstateActionTypes.FETCH_ALL_ESTATES_SUCCESS,
	payload: allEstates,
});

export const fetchAllEstatesFailure = (error) => ({
	type: EstateActionTypes.FETCH_ALL_ESTATES_FAILURE,
	payload: error,
});

export const fetchEstateStart = (username) => ({
	type: EstateActionTypes.FETCH_ESTATE_START,
	payload: username,
});

export const fetchEstateSuccess = (company) => ({
	type: EstateActionTypes.FETCH_ESTATE_SUCCESS,
	payload: company,
});

export const fetchEstateFailure = (error) => ({
	type: EstateActionTypes.FETCH_ESTATE_FAILURE,
	payload: error,
});

export const createEstateStart = (estateInfo) => ({
	type: EstateActionTypes.CREATE_ESTATE_START,
	payload : estateInfo
});

export const createEstateSuccess = () => ({
	type: EstateActionTypes.CREATE_ESTATE_SUCCESS,
});

export const createEstateFailure = (error) => ({
	type: EstateActionTypes.CREATE_ESTATE_FAILURE,
	payload: error,
});

export const updateEstateStart = (estateInfo) => ({
	type: EstateActionTypes.UPDATE_ESTATE_START,
	payload: estateInfo,
});

export const updateEstateSuccess = () => ({
	type: EstateActionTypes.UPDATE_ESTATE_SUCCESS,
});

export const updateEstateFailure = (error) => ({
	type: EstateActionTypes.UPDATE_ESTATE_FAILURE,
	payload: error,
});

export const deleteEstateStart = (estateId) => ({
	type: EstateActionTypes.DELETE_ESTATE_START,
	payload: estateId,
});

export const deleteEstateSuccess = () => ({
	type: EstateActionTypes.DELETE_ESTATE_SUCCESS,
});

export const deleteEstateFailure = (error) => ({
	type: EstateActionTypes.DELETE_ESTATE_FAILURE,
	payload: error,
});

export const clearEstateError = () => ({
	type: EstateActionTypes.CLEAR_ERROR,
});
