import React, { useEffect } from "react";
import { connect } from "react-redux";
import { checkUserSession } from "../../redux/user/user.action";

const AuthCheck = (props) => {
	const { checkUserSession, path, children } = props;
	useEffect(() => {
		if (path !== "/logout-callback") checkUserSession();
	}, [checkUserSession, path]);
	return <>{children}</>;
};

const mapDispatchToProps = (dispatch) => ({
	checkUserSession: () => dispatch(checkUserSession()),
});
export default connect(null, mapDispatchToProps)(AuthCheck);
