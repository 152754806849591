import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearMutuAncakActivityError,
	fetchMutuAncakActivitiesFailure,
	fetchMutuAncakActivitiesSuccess,
	fetchCurrentMutuAncakSuccess,
	fetchCurrentMutuAncakFailure,
	fetchCurrentMutuAncakDetailsSuccess,
	fetchCurrentMutuAncakDetailsFailure,
} from "./mutu-ancak-activity.action";
import MutuAncakActivityActionTypes from "./mutu-ancak-activity.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchMutuAncakActivities({ payload }) {
	const filters = payload;
	const userCompany = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: userCompany.id,
		pageSize: 0,
		...filters,
	});

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/harvesting-qc?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const harvestingQcs = response.data.records;
		yield put(fetchMutuAncakActivitiesSuccess(harvestingQcs));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMutuAncakActivityError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchMutuAncakActivitiesFailure(errorMessage));
	}
}

export function* onFetchMutuAncakActivitiesStart() {
	yield takeLatest(
		MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_START,
		fetchMutuAncakActivities
	);
}

export function* fetchCurrentMutuAncak({ payload }) {
	const mutuAncakId = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/harvesting-qc/${mutuAncakId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const mutuAncak = response.data.record;
		yield put(fetchCurrentMutuAncakSuccess(mutuAncak));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMutuAncakActivityError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCurrentMutuAncakFailure(errorMessage));
	}
}

export function* onFetchCurrentMutuAncakStart() {
	yield takeLatest(
		MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_START,
		fetchCurrentMutuAncak
	);
}

export function* fetchCurrentMutuAncakDetails({ payload }) {
	const mutuAncakId = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/harvesting-qc/${mutuAncakId}/details`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const mutuAncakDetails = response.data.records;
		yield put(fetchCurrentMutuAncakDetailsSuccess(mutuAncakDetails));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMutuAncakActivityError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCurrentMutuAncakDetailsFailure(errorMessage));
	}
}

export function* onFetchCurrentMutuAncakDetailsStart() {
	yield takeLatest(
		MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_START,
		fetchCurrentMutuAncakDetails
	);
}

export function* mutuAncakActivitySagas() {
	yield all([
		call(onFetchMutuAncakActivitiesStart),
		call(onFetchCurrentMutuAncakStart),
		call(onFetchCurrentMutuAncakDetailsStart),
	]);
}
