import TaksasiActivityActionTypes from "./taksasi-activity.types";

export const fetchTaksasiActivitiesStart = (filters) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_START,
	payload: filters,
});

export const fetchTaksasiActivitiesSuccess = (members) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_SUCCESS,
	payload: members,
});

export const fetchTaksasiActivitiesFailure = (error) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_FAILURE,
	payload: error,
});

export const fetchTaksasiDetailStart = (id) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_START,
	payload: id,
});

export const fetchTaksasiDetailSuccess = (members) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_SUCCESS,
	payload: members,
});

export const fetchTaksasiDetailFailure = (error) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_FAILURE,
	payload: error,
});

export const fetchTaksasiDetailTableStart = (filters) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_START,
	payload: filters,
});

export const fetchTaksasiDetailTableSuccess = (members) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_SUCCESS,
	payload: members,
});

export const fetchTaksasiDetailTableFailure = (error) => ({
	type: TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_FAILURE,
	payload: error,
});


export const clearTaksasiActivityError = () => ({
	type: TaksasiActivityActionTypes.CLEAR_ERROR,
});
