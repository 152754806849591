const UserActionTypes = {
	CHECK_USER_SESSION: "CHECK_USER_SESSION",
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL USERS
	FETCH_ALL_USERS_START: "FETCH_ALL_USERS_START",
	FETCH_ALL_USERS_SUCCESS: "FETCH_ALL_USERS_SUCCESS",
	FETCH_ALL_USERS_FAILURE: "FETCH_ALL_USERS_FAILURE",
	// Fetch USER
	FETCH_USER_START: "FETCH_USER_START",
	FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
	FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
	// CHECK ACCESS TOKEN
	CHECK_ACCESS_TOKEN_START: "CHECK_ACCESS_TOKEN_START",
	CHECK_ACCESS_TOKEN_SUCCESS: "CHECK_ACCESS_TOKEN_SUCCESS",
	CHECK_ACCESS_TOKEN_FAILURE: "CHECK_ACCESS_TOKEN_FAILURE",
	// SET REMEMBER ME
	SET_REMEMBER_ME: "SET_REMEMBER_ME",
	// GET_ACCESS_TOKEN
	GET_ACCESS_TOKEN_START: "GET_ACCESS_TOKEN_START",
	GET_ACCESS_TOKEN_SUCCESS: "GET_ACCESS_TOKEN_SUCCESS",
	GET_ACCESS_TOKEN_FAILURE: "GET_ACCESS_TOKEN_FAILURE",
	// REFRESH TOKEN
	REFRESH_ACCESS_TOKEN_START: "REFRESH_ACCESS_TOKEN_START",
	REFRESH_ACCESS_TOKEN_SUCCESS: "REFRESH_ACCESS_TOKEN_SUCCESS",
	REFRESH_ACCESS_TOKEN_FAILURE: "REFRESH_ACCESS_TOKEN_FAILURE",
	// Logout
	USER_LOGOUT_START: "USER_LOGOUT_START",
	USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
	USER_LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",
	// Forgot Password
	FORGOT_PASS_START: "FORGOT_PASS_START",
	FORGOT_PASS_SUCCESS: "FORGOT_PASS_SUCCESS",
	FORGOT_PASS_FAILURE: "FORGOT_PASS_FAILURE",
	// register USER
	REGISTER_USER_START: "REGISTER_USER_START",
	REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
	REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
	// Fetch USER_COMPANY
	FETCH_USER_COMPANY_START: "FETCH_USER_COMPANY_START",
	FETCH_USER_COMPANY_SUCCESS: "FETCH_USER_COMPANY_SUCCESS",
	FETCH_USER_COMPANY_FAILURE: "FETCH_USER_COMPANY_FAILURE",
	// Update USER
	UPDATE_USER_START: "UPDATE_USER_START",
	UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
	UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
	// Reset USER PASSWORD
	RESET_USER_PASSWORD_START: "RESET_USER_PASSWORD_START",
	RESET_USER_PASSWORD_FAILURE: "RESET_USER_PASSWORD_FAILURE",
	// Insert USER
	INSERT_USER_START: "INSERT_USER_START",
	INSERT_USER_SUCCESS: "INSERT_USER_SUCCESS",
	INSERT_USER_FAILURE: "INSERT_USER_FAILURE",
	// IMPORT USERS
	IMPORT_USERS_START: "IMPORT_USERS_START",
	IMPORT_USERS_SUCCESS: "IMPORT_USERS_SUCCESS",
	IMPORT_USERS_FAILURE: "IMPORT_USERS_FAILURE",
	// Delete USER
	DELETE_USER_START: "DELETE_USER_START",
	DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
	DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
	// SET ROLE
	SET_USER_ROLE: "SET_USER_ROLE",
};

export default UserActionTypes;
