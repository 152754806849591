import MutuAncakActivityActionTypes from "./mutu-ancak-activity.types";

export const fetchMutuAncakActivitiesStart = (filters) => ({
	type: MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_START,
	payload: filters,
});

export const fetchMutuAncakActivitiesSuccess = (members) => ({
	type: MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_SUCCESS,
	payload: members,
});

export const fetchMutuAncakActivitiesFailure = (error) => ({
	type: MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_FAILURE,
	payload: error,
});

export const clearMutuAncakActivityError = () => ({
	type: MutuAncakActivityActionTypes.CLEAR_ERROR,
});

export const fetchCurrentMutuAncakStart = (mutuAncakId) => ({
	type: MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_START,
	payload: mutuAncakId,
});

export const fetchCurrentMutuAncakSuccess = (mutuAncak) => ({
	type: MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_SUCCESS,
	payload: mutuAncak,
});

export const fetchCurrentMutuAncakFailure = (error) => ({
	type: MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_FAILURE,
	payload: error,
});

export const fetchCurrentMutuAncakDetailsStart = (mutuAncakId) => ({
	type: MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_START,
	payload: mutuAncakId,
});

export const fetchCurrentMutuAncakDetailsSuccess = (mutuAncakDetails) => ({
	type: MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_SUCCESS,
	payload: mutuAncakDetails,
});

export const fetchCurrentMutuAncakDetailsFailure = (error) => ({
	type: MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_FAILURE,
	payload: error,
});
