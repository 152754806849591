import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearHarvestActivityError,
	fetchHarvestActivitiesFailure,
	fetchHarvestActivitiesSuccess,
} from "./harvest-activity.action";
import HarvestActivityActionTypes from "./harvest-activity.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchHarvestActivities({ payload }) {
	const filters = payload;
	const userCompany = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompanyId: userCompany.id,
		pageSize: 0,
		...filters
	});

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/harvestings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const harvestActivities = response.data.records;
		yield put(fetchHarvestActivitiesSuccess(harvestActivities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearHarvestActivityError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchHarvestActivitiesFailure(errorMessage));
	}
}

export function* onFetchHarvestActivitiesStart() {
	yield takeLatest(
		HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_START,
		fetchHarvestActivities
	);
}

export function* harvestActivitySagas() {
	yield all([call(onFetchHarvestActivitiesStart)]);
}
