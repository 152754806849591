import HarvesterActionTypes from "./harvester.types";

export const clearHarvesterError = () => ({
	type: HarvesterActionTypes.CLEAR_ERROR,
});

export const fetchHarvestersStart = () => ({
	type: HarvesterActionTypes.FETCH_HARVESTERS_START,
});

export const fetchHarvestersSuccess = (harvesters) => ({
	type: HarvesterActionTypes.FETCH_HARVESTERS_SUCCESS,
	payload: harvesters,
});

export const fetchHarvestersFailure = (error) => ({
	type: HarvesterActionTypes.FETCH_HARVESTERS_FAILURE,
	payload: error,
});

export const insertHarvesterStart = (harvesterInfo) => ({
	type: HarvesterActionTypes.INSERT_HARVESTER_START,
	payload: harvesterInfo,
});

export const insertHarvesterSuccess = () => ({
	type: HarvesterActionTypes.INSERT_HARVESTER_SUCCESS,
});

export const insertHarvesterFailure = (error) => ({
	type: HarvesterActionTypes.INSERT_HARVESTER_FAILURE,
	payload: error,
});

export const updateHarvesterStart = (harvesterInfo) => ({
	type: HarvesterActionTypes.UPDATE_HARVESTER_START,
	payload: harvesterInfo,
});

export const updateHarvesterSuccess = () => ({
	type: HarvesterActionTypes.UPDATE_HARVESTER_SUCCESS,
});

export const updateHarvesterFailure = (error) => ({
	type: HarvesterActionTypes.UPDATE_HARVESTER_FAILURE,
	payload: error,
});

export const deleteHarvesterStart = (harvesterId) => ({
	type: HarvesterActionTypes.DELETE_HARVESTER_START,
	payload: harvesterId,
});

export const deleteHarvesterSuccess = () => ({
	type: HarvesterActionTypes.DELETE_HARVESTER_SUCCESS,
});

export const deleteHarvesterFailure = (error) => ({
	type: HarvesterActionTypes.DELETE_HARVESTER_FAILURE,
	payload: error,
});

export const importHarvestersStart = (importInfo) => ({
	type: HarvesterActionTypes.IMPORT_HARVESTERS_START,
	payload: importInfo,
});

export const importHarvestersSuccess = () => ({
	type: HarvesterActionTypes.IMPORT_HARVESTERS_SUCCESS,
});

export const importHarvestersFailure = (error) => ({
	type: HarvesterActionTypes.IMPORT_HARVESTERS_FAILURE,
	payload: error,
});
