import React from "react";
import { useStyles } from "./Footer.styles";

function Footer() {
	const classes = useStyles();
	return (
		<footer>
			<div className={classes.footerContainer}></div>
		</footer>
	);
}

export default Footer;
