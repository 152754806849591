import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import snackbarReducer from "./snackbar/snackbar.reducer";
import joinRequestReducer from "./join-request/join-request.reducer";
import companyReducer from "./company/company.reducer";
import memberReducer from "./member/member.reducer";
import harvesterReducer from "./harvester/harvester.reducer";
import harvestActivityReducer from "./harvest-activity/harvest-activity.reducer";
import blockReducer from "./block/block.reducer";
import taksasiActivityReducer from "./taksasi-activity/taksasi-activity.reducer";
import taksasiVsPanenActivityReducer from "./taksasiVsPanen-activity/taksasiVsPanen-activity.reducer";
import mutuAncakActivityReducer from "./mutu-ancak-activity/mutu-ancak-activity.reducer";
import estateReducer from "./estate/estate.reducer";
import afdelingReducer from "./afdeling/afdeling.reducer";

const persistConfig = {
	key: "root",
	storage,
	blacklist: [
		"semaiUser",
		"snackbar",
		"joinRequest",
		"company",
		"member",
		"harvester",
		"harvestActivity",
		"block",
		"taksasiActivity",
		"taksasiVsPanenActivity",
		"mutuAncakActivity",
		"estate",
		"afdeling"
	],
};

const userPersistConfig = {
	key: "semaiUser",
	storage,
	whitelist: ["currentUser", "token", "provider", "refreshToken"],
};

const rootReducer = combineReducers({
	semaiUser: persistReducer(userPersistConfig, userReducer),
	snackbar: snackbarReducer,
	joinRequest: joinRequestReducer,
	company: companyReducer,
	member: memberReducer,
	harvester: harvesterReducer,
	harvestActivity: harvestActivityReducer,
	block: blockReducer,
	taksasiActivity: taksasiActivityReducer,
	mutuAncakActivity: mutuAncakActivityReducer,
	estate : estateReducer,
	afdeling : afdelingReducer,
	taksasiVsPanenActivity: taksasiVsPanenActivityReducer,
});

export default persistReducer(persistConfig, rootReducer);
