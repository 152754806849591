const TaksasiActivityActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch Taksasi Activities
	FETCH_TAKSASI_ACTIVITIES_START: "FETCH_TAKSASI_ACTIVITIES_START",
	FETCH_TAKSASI_ACTIVITIES_SUCCESS: "FETCH_TAKSASI_ACTIVITIES_SUCCESS",
	FETCH_TAKSASI_ACTIVITIES_FAILURE: "FETCH_TAKSASI_ACTIVITIES_FAILURE",
	//Fetch Taksasi Detail
	FETCH_TAKSASI_DETAIL_START: "FETCH_TAKSASI_DETAIL_START",
	FETCH_TAKSASI_DETAIL_SUCCESS: "FETCH_TAKSASI_DETAIL_SUCCESS",
	FETCH_TAKSASI_DETAIL_FAILURE: "FETCH_TAKSASI_DETAIL_FAILURE",
	//Fecth Taksasi DEtail Table
	FETCH_TAKSASI_DETAIL_TABLE_START: "FETCH_TAKSASI_DETAIL_TABLE_START",
	FETCH_TAKSASI_DETAIL_TABLE_SUCCESS: "FETCH_TAKSASI_DETAIL_TABLE_SUCCESS",
	FETCH_TAKSASI_DETAIL_TABLE_FAILURE: "FETCH_TAKSASI_DETAIL_TABLE_FAILURE",
};

export default TaksasiActivityActionTypes;
