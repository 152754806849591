const HarvesterActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch HARVESTERS
	FETCH_HARVESTERS_START: "FETCH_HARVESTERS_START",
	FETCH_HARVESTERS_SUCCESS: "FETCH_HARVESTERS_SUCCESS",
	FETCH_HARVESTERS_FAILURE: "FETCH_HARVESTERS_FAILURE",
	// Insert HARVESTER
	INSERT_HARVESTER_START: "INSERT_HARVESTER_START",
	INSERT_HARVESTER_SUCCESS: "INSERT_HARVESTER_SUCCESS",
	INSERT_HARVESTER_FAILURE: "INSERT_HARVESTER_FAILURE",
	// Update HARVESTER
	UPDATE_HARVESTER_START: "UPDATE_HARVESTER_START",
	UPDATE_HARVESTER_SUCCESS: "UPDATE_HARVESTER_SUCCESS",
	UPDATE_HARVESTER_FAILURE: "UPDATE_HARVESTER_FAILURE",
	// Delete HARVESTER
	DELETE_HARVESTER_START: "DELETE_HARVESTER_START",
	DELETE_HARVESTER_SUCCESS: "DELETE_HARVESTER_SUCCESS",
	DELETE_HARVESTER_FAILURE: "DELETE_HARVESTER_FAILURE",
	// Import HARVESTERS
	IMPORT_HARVESTERS_START: "IMPORT_HARVESTERS_START",
	IMPORT_HARVESTERS_SUCCESS: "IMPORT_HARVESTERS_SUCCESS",
	IMPORT_HARVESTERS_FAILURE: "IMPORT_HARVESTERS_FAILURE",
};

export default HarvesterActionTypes;
