import MemberActionTypes from "./member.types";

export const fetchMembersStart = (companyId) => ({
	type: MemberActionTypes.FETCH_MEMBERS_START,
	payload: companyId,
});

export const fetchMembersSuccess = (members) => ({
	type: MemberActionTypes.FETCH_MEMBERS_SUCCESS,
	payload: members,
});

export const fetchMembersFailure = (error) => ({
	type: MemberActionTypes.FETCH_MEMBERS_FAILURE,
	payload: error,
});

export const deleteMemberStart = (userId) => ({
	type: MemberActionTypes.DELETE_MEMBER_START,
	payload: userId,
});

export const deleteMemberSuccess = () => ({
	type: MemberActionTypes.DELETE_MEMBER_SUCCESS,
});

export const deleteMemberFailure = (error) => ({
	type: MemberActionTypes.DELETE_MEMBER_FAILURE,
	payload: error,
});

export const clearMemberError = () => ({
	type: MemberActionTypes.CLEAR_ERROR,
});
