import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "./Content.styles";

const Content = (props) => {
	const classes = useStyles();
	return <Grid container justify="center" className={classes.content}>{props.children}</Grid>;
};

export default Content;
