import JoinRequestActionTypes from "./join-request.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	plural: [],
};

const joinRequestReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case JoinRequestActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case JoinRequestActionTypes.FETCH_JOIN_REQUESTS_START:
		case JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_START:
		case JoinRequestActionTypes.REJECT_JOIN_REQUEST_START:
			return {
				...state,
				loading: true,
			};
		case JoinRequestActionTypes.FETCH_JOIN_REQUESTS_SUCCESS:
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		case JoinRequestActionTypes.FETCH_JOIN_REQUESTS_FAILURE:
		case JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_FAILURE:
		case JoinRequestActionTypes.REJECT_JOIN_REQUEST_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_SUCCESS:
		case JoinRequestActionTypes.REJECT_JOIN_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default joinRequestReducer;
