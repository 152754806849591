// core components/views for Public layout
import { lazy } from "react";
const LogoutCallback = lazy(() =>
	import("../pages/LogoutCallback/LogoutCallback")
);
const LoginCallback = lazy(() =>
	import("../pages/LoginCallback/LoginCallback")
);
const Register = lazy(() => import("../pages/Register/Register"));
const RegisterSuccess = lazy(() =>
	import("../pages/RegisterSuccess/RegisterSuccess")
);
const EmailConfirmCallback = lazy(() =>
	import("../pages/EmailConfirmCallback/EmailConfirmCallback")
);
const JoinRequestsPage = lazy(() =>
	import("../pages/JoinRequestsPage/JoinRequestsPage")
);
const UpdateUser = lazy(() => import("../pages/UpdateUser/UpdateUser"));
const MembersPage = lazy(() => import("../pages/MembersPage/MembersPage"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const Activities = lazy(() => import("../pages/Activities/Activities"));
const MasterData = lazy(() => import("../pages/MasterData/MasterData"));
const ResetPassword = lazy(() =>
	import("../pages/ResetPassword/ResetPassword")
);
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Login = lazy(() => import("../pages/Login/Login"));
const ForgotPassword = lazy(() =>
	import("../pages/ForgotPassword/ForgotPassword")
);
const ForgotPasswordSuccess = lazy(() =>
	import("../pages/ForgotPassword/ForgotPasswordSuccess")
);
const TaksasiDetail = lazy(() =>
	import("../pages/TaksasiDetail/TaksasiDetail")
);
const MutuAncakDetail = lazy(() =>
	import("../pages/MutuAncakDetail/MutuAncakDetail")
);
const PrivacyPolicy = lazy(() =>
	import("../pages/PrivacyPolicy/PrivacyPolicy")
);

// check auth values = [true, false, null], use null to have public page (can be accessed whether the user is authenticated or not)
const publicRoutes = [
	{
		path: "/",
		component: Dashboard,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/login",
		component: Login,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/forgot-password-success",
		component: ForgotPasswordSuccess,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/login-callback",
		component: LoginCallback,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/logout-callback",
		component: LogoutCallback,
		withHeader: false,
		withFooter: false,
		checkAuth: true,
	},
	{
		path: "/register",
		component: Register,
		withHeader: true,
		withFooter: true,
		checkAuth: false,
	},
	{
		path: "/register-success",
		component: RegisterSuccess,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
	},
	{
		path: "/email-confirm-success",
		component: EmailConfirmCallback,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
	},
	{
		path: "/join-requests",
		component: JoinRequestsPage,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/members",
		component: MembersPage,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/update-user",
		component: UpdateUser,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/profile",
		component: Profile,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/activities",
		component: Activities,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/master-data",
		component: MasterData,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/reset-password",
		component: ResetPassword,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/activities/taksasi/:taksasiId",
		component: TaksasiDetail,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/activities/mutu-ancak/:mutuAncakId",
		component: MutuAncakDetail,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
	},
	{
		path: "/privacy",
		component: PrivacyPolicy,
		withHeader: false,
		withFooter: false,
		checkAuth: null,
	},
];

export default publicRoutes;
