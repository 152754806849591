import JoinRequestActionTypes from "./join-request.types";

export const clearJoinRequestError = () => ({
	type: JoinRequestActionTypes.CLEAR_ERROR,
});
// ---------------- fetch joinRequests ----------------
export const fetchJoinRequestsStart = () => ({
	type: JoinRequestActionTypes.FETCH_JOIN_REQUESTS_START,
});
export const fetchJoinRequestsSuccess = (joinRequests) => ({
	type: JoinRequestActionTypes.FETCH_JOIN_REQUESTS_SUCCESS,
	payload: joinRequests,
});
export const fetchJoinRequestsFailure = (error) => ({
	type: JoinRequestActionTypes.FETCH_JOIN_REQUESTS_FAILURE,
	payload: error,
});
// ---------------- end fetch joinRequests ----------------
// ---------------- accept joinRequest ----------------
export const acceptJoinRequestStart = (requestInfo) => ({
	type: JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_START,
	payload: requestInfo,
});
export const acceptJoinRequestSuccess = () => ({
	type: JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_SUCCESS,
});
export const acceptJoinRequestFailure = (error) => ({
	type: JoinRequestActionTypes.ACCEPT_JOIN_REQUEST_FAILURE,
	payload: error,
});
// ---------------- end accept joinRequest ----------------
// ---------------- reject joinRequest ----------------
export const rejectJoinRequestStart = (requestId) => ({
	type: JoinRequestActionTypes.REJECT_JOIN_REQUEST_START,
	payload: requestId,
});
export const rejectJoinRequestSuccess = () => ({
	type: JoinRequestActionTypes.REJECT_JOIN_REQUEST_SUCCESS,
});
export const rejectJoinRequestFailure = (error) => ({
	type: JoinRequestActionTypes.REJECT_JOIN_REQUEST_FAILURE,
	payload: error,
});
// ---------------- end reject joinRequest ----------------
