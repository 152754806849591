import EstateActionTypes from "./estate.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	singular: null,
	plural: [],
	allEstates: [],
};

const estateReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EstateActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case EstateActionTypes.FETCH_ALL_ESTATES_START:
		case EstateActionTypes.FETCH_ESTATE_START:
		case EstateActionTypes.CREATE_ESTATE_START:
		case EstateActionTypes.UPDATE_ESTATE_START:
		case EstateActionTypes.DELETE_ESTATE_START:
			return {
				...state,
				loading: true,
			};
		case EstateActionTypes.FETCH_ALL_ESTATES_FAILURE:
		case EstateActionTypes.FETCH_ESTATE_FAILURE:
		case EstateActionTypes.CREATE_ESTATE_FAILURE:
		case EstateActionTypes.UPDATE_ESTATE_FAILURE:
		case EstateActionTypes.DELETE_ESTATE_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case EstateActionTypes.CREATE_ESTATE_SUCCESS: 
		case EstateActionTypes.UPDATE_ESTATE_SUCCESS: 
		case EstateActionTypes.DELETE_ESTATE_SUCCESS: {
			return {
				...state,
				loading: false,
			};
		}
		case EstateActionTypes.FETCH_ALL_ESTATES_SUCCESS: {
			return {
				...state,
				allEstates: action.payload,
				loading: false,
			};
		}
		case EstateActionTypes.FETCH_ESTATE_SUCCESS: {
			return {
				...state,
				singular: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default estateReducer;
