import AfdelingActionTypes from "./afdeling.types";

export const fetchAllAfdelingStart = () => ({
	type: AfdelingActionTypes.FETCH_ALL_AFDELING_START,
});

export const fetchAllAfdelingSuccess = (allAfdeling) => ({
	type: AfdelingActionTypes.FETCH_ALL_AFDELING_SUCCESS,
	payload: allAfdeling,
});

export const fetchAllAfdelingFailure = (error) => ({
	type: AfdelingActionTypes.FETCH_ALL_AFDELING_FAILURE,
	payload: error,
});

export const fetchAfdelingStart = (username) => ({
	type: AfdelingActionTypes.FETCH_AFDELING_START,
	payload: username,
});

export const fetchAfdelingSuccess = (company) => ({
	type: AfdelingActionTypes.FETCH_AFDELING_SUCCESS,
	payload: company,
});

export const fetchAfdelingFailure = (error) => ({
	type: AfdelingActionTypes.FETCH_AFDELING_FAILURE,
	payload: error,
});

export const fetchAfdelingByEstateStart = (id) => ({
	type: AfdelingActionTypes.FETCH_ESTATE_AFDELING_START,
	payload: id,
});

export const fetchAfdelingByEstateSuccess = (company) => ({
	type: AfdelingActionTypes.FETCH_ESTATE_AFDELING_SUCCESS,
	payload: company,
});

export const fetchAfdelingByEstateFailure = (error) => ({
	type: AfdelingActionTypes.FETCH_ESTATE_AFDELING_FAILURE,
	payload: error,
});

export const createAfdelingStart = (info) => ({
	type: AfdelingActionTypes.CREATE_AFDELING_START,
	payload : info
});

export const createAfdelingSuccess = () => ({
	type: AfdelingActionTypes.CREATE_AFDELING_SUCCESS,
});

export const createAfdelingFailure = (error) => ({
	type: AfdelingActionTypes.CREATE_AFDELING_FAILURE,
	payload: error,
});

export const updateAfdelingStart = ({ id , info, version }) => ({
	type: AfdelingActionTypes.UPDATE_AFDELING_START,
	payload: { id , info, version},
});

export const updateAfdelingSuccess = () => ({
	type: AfdelingActionTypes.UPDATE_AFDELING_SUCCESS,
});

export const updateAfdelingFailure = (error) => ({
	type: AfdelingActionTypes.UPDATE_AFDELING_FAILURE,
	payload: error,
});

export const deleteAfdelingStart = ( id ) => ({
	type: AfdelingActionTypes.DELETE_AFDELING_START,
	payload: id,
});

export const deleteAfdelingSuccess = () => ({
	type: AfdelingActionTypes.DELETE_AFDELING_SUCCESS,
});

export const deleteAfdelingFailure = (error) => ({
	type: AfdelingActionTypes.DELETE_AFDELING_FAILURE,
	payload: error,
});

export const clearAfdelingError = () => ({
	type: AfdelingActionTypes.CLEAR_ERROR,
});
