import { KEYCLOAK_ID, REDIRECT_URI, SERVER_ENV } from "../../configs/env";
import queryString from "query-string";
import {
	CODE_CHALLENGE_METHOD,
	CODE_CHALLENGE,
} from "../../configs/PCKEConfigs";

const Keycloak = () => {
	const baseUrlKeycloak =
		SERVER_ENV === "development"
			? "accounts-dev.semaigroup.com"
			: "accounts.semaigroup.com";
	return queryString.stringifyUrl({
		url: `https://${baseUrlKeycloak}/auth/realms/semai-x/protocol/openid-connect/auth`,
		query: {
			client_id: KEYCLOAK_ID,
			redirect_uri: REDIRECT_URI,
			response_type: "code",
			scope: ["openid", "profile"].join(" "),
			state: JSON.stringify({ provider: "Keycloak" }),
			code_challenge: CODE_CHALLENGE,
			code_challenge_method: CODE_CHALLENGE_METHOD,
		},
	});
};

export default Keycloak();
