import TaksasiVsPanenActivityActionTypes from "./taksasiVsPanen-activity.types";

export const fetchTaksasiVsPanenStart = (filters) => ({
	type: TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_START,
	payload: filters,
});

export const fetchTaksasiVsPanenSuccess = (result) => ({
	type: TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_SUCCESS,
	payload: result,
});

export const fetchTaksasiVsPanenFailure = (error) => ({
	type: TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_FAILURE,
	payload: error,
});

export const clearTaksasiVsPanenActivityError = () => ({
	type: TaksasiVsPanenActivityActionTypes.CLEAR_ERROR,
});
