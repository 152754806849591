import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./redux/store";
import axios from "axios";

const hist = createBrowserHistory();

const onBeforeLift = () => {
	const token = store.getState().semaiUser.token;
	if (!!token)
		axios.defaults.headers.common = {
			Authorization: `Bearer ${token}`,
		};
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate onBeforeLift={onBeforeLift} persistor={persistor}>
			<Router history={hist}>
				<App />
			</Router>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
