const AfdelingActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL AFDELING
	FETCH_ALL_AFDELING_START: "FETCH_ALL_AFDELING_START",
	FETCH_ALL_AFDELING_SUCCESS: "FETCH_ALL_AFDELING_SUCCESS",
	FETCH_ALL_AFDELING_FAILURE: "FETCH_ALL_AFDELING_FAILURE",
	// Fetch ESTATE AFDELING
	FETCH_ESTATE_AFDELING_START: "FETCH_ESTATE_AFDELING_START",
	FETCH_ESTATE_AFDELING_SUCCESS: "FETCH_ESTATE_AFDELING_SUCCESS",
	FETCH_ESTATE_AFDELING_FAILURE: "FETCH_ESTATE_AFDELING_FAILURE",
	// Fetch AFDELING
	FETCH_AFDELING_START: "FETCH_AFDELING_START",
	FETCH_AFDELING_SUCCESS: "FETCH_AFDELING_SUCCESS",
	FETCH_AFDELING_FAILURE: "FETCH_AFDELING_FAILURE",
	// CREATE AFDELING
	CREATE_AFDELING_START: "CREATE_AFDELING_START",
	CREATE_AFDELING_SUCCESS: "CREATE_AFDELING_SUCCESS",
	CREATE_AFDELING_FAILURE: "CREATE_AFDELING_FAILURE",
	// Update AFDELING
	UPDATE_AFDELING_START: "UPDATE_AFDELING_START",
	UPDATE_AFDELING_SUCCESS: "UPDATE_AFDELING_SUCCESS",
	UPDATE_AFDELING_FAILURE: "UPDATE_AFDELING_FAILURE",
	// Delete AFDELING
	DELETE_AFDELING_START: "DELETE_AFDELING_START",
	DELETE_AFDELING_SUCCESS: "DELETE_AFDELING_SUCCESS",
	DELETE_AFDELING_FAILURE: "DELETE_AFDELING_FAILURE",
};

export default AfdelingActionTypes;
