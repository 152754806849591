import { createSelector } from "reselect";

const selectEstate = (state) => state.estate;

export const selectEstateSingular = createSelector(
	[selectEstate],
	(estate) => estate.singular
);

export const selectEstateError = createSelector(
	[selectEstate],
	(estate) => estate.error
);

export const selectEstateLoading = createSelector(
	[selectEstate],
	(estate) => estate.loading
);

export const selectAllEstates = createSelector(
	[selectEstate],
	(estate) => estate.allEstates
);

export const selectEstatePlural = createSelector(
	[selectEstate],
	(estate) => estate.plural
);

export const selectActiveEstatesTotal = createSelector(
	[selectAllEstates],
	(estates) => estates.length
);