import HarvestActivityActionTypes from "./harvest-activity.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	plural: [],
};

const harvestActivityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HarvestActivityActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_START:
			return {
				...state,
				loading: true,
			};
		case HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default harvestActivityReducer;
