import AzureAuthUrl from "../auth/AuthEndpointSetup/Azure";
import GoogleAuthUrl from "../auth/AuthEndpointSetup/Google";
import FacebookAuthUrl from "../auth/AuthEndpointSetup/Facebook";
import KeyclokaAuthUrl from "../auth/AuthEndpointSetup/Keycloak";

export const getAuthUrl = (provider) => {
	switch (provider) {
		case "Microsoft":
			return AzureAuthUrl;
		case "Google":
			return GoogleAuthUrl;
		case "Facebook":
			return FacebookAuthUrl;
		case "Keycloak":
			return KeyclokaAuthUrl;
		default:
			break;
	}
};
