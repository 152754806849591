const CompanyActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL COMPANIES
	FETCH_ALL_COMPANIES_START: "FETCH_ALL_COMPANIES_START",
	FETCH_ALL_COMPANIES_SUCCESS: "FETCH_ALL_COMPANIES_SUCCESS",
	FETCH_ALL_COMPANIES_FAILURE: "FETCH_ALL_COMPANIES_FAILURE",
	// Fetch CURRENT USER COMPANY
	FETCH_CURRENT_USER_COMPANY_START: "FETCH_CURRENT_USER_COMPANY_START",
	FETCH_CURRENT_USER_COMPANY_SUCCESS: "FETCH_CURRENT_USER_COMPANY_SUCCESS",
	FETCH_CURRENT_USER_COMPANY_FAILURE: "FETCH_CURRENT_USER_COMPANY_FAILURE",
	// Update COMPANY_CODE
	UPDATE_COMPANY_CODE_START: "UPDATE_COMPANY_CODE_START",
	UPDATE_COMPANY_CODE_SUCCESS: "UPDATE_COMPANY_CODE_SUCCESS",
	UPDATE_COMPANY_CODE_FAILURE: "UPDATE_COMPANY_CODE_FAILURE",
	// Update COMPANY
	UPDATE_COMPANY_START: "UPDATE_COMPANY_START",
	UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
	UPDATE_COMPANY_FAILURE: "UPDATE_COMPANY_FAILURE",
};

export default CompanyActionTypes;
