import TaksasiActivityActionTypes from "./taksasi-activity.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	plural: [],
	detail: [],
	table: [],
};

const taksasiActivityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TaksasiActivityActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_START:
		case TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_START:
		case TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_START:
			return {
				...state,
				loading: true,
			};
		case TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_FAILURE:
		case TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_FAILURE:
		case TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_SUCCESS: {
			return {
				...state,
				detail: action.payload,
				loading: false,
			};
		}
		case TaksasiActivityActionTypes.FETCH_TAKSASI_DETAIL_TABLE_SUCCESS:{
			return {
				...state,
				table: action.payload,
				loading: false,
			};
		}
		case TaksasiActivityActionTypes.FETCH_TAKSASI_ACTIVITIES_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default taksasiActivityReducer;
