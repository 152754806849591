const EstateActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch ALL ESTATES
	FETCH_ALL_ESTATES_START: "FETCH_ALL_ESTATES_START",
	FETCH_ALL_ESTATES_SUCCESS: "FETCH_ALL_ESTATES_SUCCESS",
	FETCH_ALL_ESTATES_FAILURE: "FETCH_ALL_ESTATES_FAILURE",
	// Fetch ESTATE
	FETCH_ESTATE_START: "FETCH_ESTATE_START",
	FETCH_ESTATE_SUCCESS: "FETCH_ESTATE_SUCCESS",
	FETCH_ESTATE_FAILURE: "FETCH_ESTATE_FAILURE",
	// CREATE ESTATE
	CREATE_ESTATE_START: "CREATE_ESTATE_START",
	CREATE_ESTATE_SUCCESS: "CREATE_ESTATE_SUCCESS",
	CREATE_ESTATE_FAILURE: "CREATE_ESTATE_FAILURE",
	// Update ESTATE
	UPDATE_ESTATE_START: "UPDATE_ESTATE_START",
	UPDATE_ESTATE_SUCCESS: "UPDATE_ESTATE_SUCCESS",
	UPDATE_ESTATE_FAILURE: "UPDATE_ESTATE_FAILURE",
	// delete ESTATE
	DELETE_ESTATE_START: "DELETE_ESTATE_START",
	DELETE_ESTATE_SUCCESS: "DELETE_ESTATE_SUCCESS",
	DELETE_ESTATE_FAILURE: "DELETE_ESTATE_FAILURE",
};

export default EstateActionTypes;
