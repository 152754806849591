import AfdelingActionTypes from "./afdeling.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	singular: null,
	plural: [],
	allAfdeling: [],
};

const afdelingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AfdelingActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case AfdelingActionTypes.FETCH_ALL_AFDELING_START:
		case AfdelingActionTypes.FETCH_AFDELING_START:
		case AfdelingActionTypes.CREATE_AFDELING_START:
		case AfdelingActionTypes.UPDATE_AFDELING_START:
		case AfdelingActionTypes.DELETE_AFDELING_START:
		case AfdelingActionTypes.FETCH_ESTATE_AFDELING_START: 
			return {
				...state,
				loading: true,
			};
		case AfdelingActionTypes.FETCH_ALL_AFDELING_FAILURE:
		case AfdelingActionTypes.FETCH_AFDELING_FAILURE:
		case AfdelingActionTypes.CREATE_AFDELING_FAILURE:
		case AfdelingActionTypes.UPDATE_AFDELING_FAILURE:
		case AfdelingActionTypes.DELETE_AFDELING_FAILURE:
		case AfdelingActionTypes.FETCH_ESTATE_AFDELING_FAILURE: 
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case AfdelingActionTypes.CREATE_AFDELING_SUCCESS: 
		case AfdelingActionTypes.UPDATE_AFDELING_SUCCESS: 
		case AfdelingActionTypes.DELETE_AFDELING_SUCCESS:{
			return {
				...state,
				loading: false,
			};
		}
		case AfdelingActionTypes.FETCH_ALL_AFDELING_SUCCESS: {
			return {
				...state,
				allAfdeling: action.payload,
				loading: false,
			};
		}
		case AfdelingActionTypes.FETCH_AFDELING_SUCCESS: {
			return {
				...state,
				singular: action.payload,
				loading: false,
			};
		}
		case AfdelingActionTypes.FETCH_ESTATE_AFDELING_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default afdelingReducer;
