import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	content: {
		position: "relative",
		marginTop: "120px",
		background: "#FFFFFF",
		zIndex: 0,
		paddingBottom: "90px",
		[theme.breakpoints.down("sm")]: {
			// marginTop: "50px",
		},
	},
}));
