import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import publicRoutes from "./routes/public.routes";
import { selectCurrentUser } from "./redux/user/user.selector";
import Content from "./components/Content/Content";
import Header from "./components/Header/Header";
import NotFound from "./pages/ErrorPages/NotFound";
import "./App.css";
import Footer from "./components/Footer/Footer";
import { getAuthUrl } from "./helpers/Auth.helper";
import CustomSnackbar from "./components/CustomSnackbar/CustomSnackbar";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./App.styles";
import { Suspense } from "react";
import LoadingBackdrop from "./components/LoadingBackdrop/LoadingBackdrop";
import AuthCheck from "./components/AuthCheck/AuthCheck";
import 'react-image-lightbox/style.css';

const switchRoutes = (props) => (
	<Switch>
		{publicRoutes.map((prop, key) => {
			switch (prop.checkAuth) {
				// routes that only can be accessed when the user is not authenticated
				case false:
					return (
						<Route
							path={prop.path}
							key={key}
							exact
							render={() => {
								if (prop.path === "/login") {
									window.location.href =
										getAuthUrl("Keycloak");
								} else {
									return (
										<>
											{prop.withHeader && <Header />}
											{props.currentUser ? (
												<Redirect to="/" />
											) : (
												<Content>
													{React.createElement(
														prop.component
													)}
												</Content>
											)}
											{prop.withFooter && <Footer />}
										</>
									);
								}
							}}
						/>
					);
				// routes that needs to be authenticated
				case true:
					return (
						<Route
							path={prop.path}
							key={key}
							exact
							render={() => (
								<AuthCheck path={prop.path}>
									{prop.withHeader && <Header />}
									<Content>
										{props.currentUser ? (
											React.createElement(prop.component)
										) : (
											<Redirect to="/login" />
										)}
									</Content>
									{prop.withFooter && <Footer />}
								</AuthCheck>
							)}
						/>
					);
				default:
					return (
						<Route
							path={prop.path}
							key={key}
							exact
							render={() => (
								<>
									{prop.withHeader && <Header />}
									<Content>
										{React.createElement(prop.component)}
									</Content>
									{prop.withFooter && <Footer />}
								</>
							)}
						/>
					);
			}
		})}
		<Route
			render={() => (
				<>
					{/* <Header /> */}
					<Content>{React.createElement(NotFound)}</Content>
					{/* <Footer /> */}
				</>
			)}
		/>
	</Switch>
);

const App = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<div className="appContainer">
				<CustomSnackbar />
				<Suspense
					fallback={
						<LoadingBackdrop
							color="#2E7D32"
							backgroundColor="white"
							open={true}
						/>
					}
				>
					{switchRoutes(props)}
				</Suspense>
			</div>
		</ThemeProvider>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(App);
