const MutuAncakActivityActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch MutuAncak Activities
	FETCH_MUTU_ANCAK_ACTIVITIES_START: "FETCH_MUTU_ANCAK_ACTIVITIES_START",
	FETCH_MUTU_ANCAK_ACTIVITIES_SUCCESS: "FETCH_MUTU_ANCAK_ACTIVITIES_SUCCESS",
	FETCH_MUTU_ANCAK_ACTIVITIES_FAILURE: "FETCH_MUTU_ANCAK_ACTIVITIES_FAILURE",
	// Fetch Current MutuAncak
	FETCH_CURRENT_MUTU_ANCAK_START: "FETCH_CURRENT_MUTU_ANCAK_START",
	FETCH_CURRENT_MUTU_ANCAK_SUCCESS: "FETCH_CURRENT_MUTU_ANCAK_SUCCESS",
	FETCH_CURRENT_MUTU_ANCAK_FAILURE: "FETCH_CURRENT_MUTU_ANCAK_FAILURE",
	// Fetch Current MutuAncak Details
	FETCH_CURRENT_MUTU_ANCAK_DETAILS_START: "FETCH_CURRENT_MUTU_ANCAK_DETAILS_START",
	FETCH_CURRENT_MUTU_ANCAK_DETAILS_SUCCESS: "FETCH_CURRENT_MUTU_ANCAK_DETAILS_SUCCESS",
	FETCH_CURRENT_MUTU_ANCAK_DETAILS_FAILURE: "FETCH_CURRENT_MUTU_ANCAK_DETAILS_FAILURE",
};

export default MutuAncakActivityActionTypes;
