import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearCompanyError,
	fetchCurrentUserCompanySuccess,
	fetchCurrentUserCompanyFailure,
	fetchCurrentUserCompanyStart,
	updateCompanyCodeSuccess,
	updateCompanyCodeFailure,
	updateCompanySuccess,
	updateCompanyFailure,
	fetchAllCompaniesSuccess,
	fetchAllCompaniesFailure,
} from "./company.action";
import { selectCurrentUserCompany } from "./company.selector";
import CompanyActionTypes from "./company.types";

export function* fetchCurrentUserCompany() {
	const currentUser = yield select(selectCurrentUser);
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/companies/${currentUser.companyId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const company = response.data.record;
		yield put(fetchCurrentUserCompanySuccess(company));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCompanyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCurrentUserCompanyFailure(errorMessage));
	}
}

export function* onFetchCompanyStart() {
	yield takeLatest(
		CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_START,
		fetchCurrentUserCompany
	);
}

export function* updateCompanyCode() {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/companies/${company.id}/reset-code`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateCompanyCodeSuccess());
		yield put(fetchCurrentUserCompanyStart(currentUser.username));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCompanyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateCompanyCodeFailure(errorMessage));
	}
}

export function* onUpdateCompanyCodeStart() {
	yield takeLatest(
		CompanyActionTypes.UPDATE_COMPANY_CODE_START,
		updateCompanyCode
	);
}

export function* updateCompany({ payload }) {
	const companyInfo = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);

	const data = {
		address: companyInfo.companyAddress,
		addressExt: "",
		areaHa: companyInfo.luas,
		city: companyInfo.city,
		companyName: companyInfo.companyName,
		contactEmail: company.contactEmail,
		contactName: company.contactName,
		contactPhone: company.contactPhone,
		country: company.country,
		createdBy: company.createdBy,
		createdTime: moment(
			`${company.createdTime}`.replace("[UTC]", "")
		)
			.utc()
			.format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		deletedBy: null,
		deletedTime: null,
		id: company.id,
		isDeleted: 0,
		joinRequestCode: company.joinRequestCode,
		numOfClerks: companyInfo.kerani,
		numOfHarvesters: companyInfo.pemanen,
        numOfSupervisors: companyInfo.mandor,
		phone: companyInfo.companyPhoneNumber,
		postalCode: companyInfo.zipCode,
		province: companyInfo.province,
		syncedStatus: 1,
		syncedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		updatedBy: currentUser.username,
		updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		url: companyInfo.companyWebsite,
		version: company.version + 1,
	};
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BASE_URL_API}/companies/${company.id}`,
			data,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateCompanySuccess());
		yield put(fetchCurrentUserCompanyStart(currentUser.username));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCompanyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateCompanyFailure(errorMessage));
	}
}

export function* onUpdateCompanyStart() {
	yield takeLatest(CompanyActionTypes.UPDATE_COMPANY_START, updateCompany);
}

export function* fetchAllCompanies() {
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/companies`,
		});

		if (!response.data.success) {
			console.log("error");
		}

		const allCompanies = response.data.records;
		yield put(fetchAllCompaniesSuccess(allCompanies));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCompanyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllCompaniesFailure(errorMessage));
	}
}

export function* onFetchAllCompaniesStart() {
	yield takeLatest(
		CompanyActionTypes.FETCH_ALL_COMPANIES_START,
		fetchAllCompanies
	);
}

export function* companySagas() {
	yield all([
		call(onFetchAllCompaniesStart),
		call(onFetchCompanyStart),
		call(onUpdateCompanyCodeStart),
		call(onUpdateCompanyStart),
	]);
}
