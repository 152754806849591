import CompanyActionTypes from "./company.types";

export const fetchAllCompaniesStart = () => ({
	type: CompanyActionTypes.FETCH_ALL_COMPANIES_START,
});

export const fetchAllCompaniesSuccess = (allCompanies) => ({
	type: CompanyActionTypes.FETCH_ALL_COMPANIES_SUCCESS,
	payload: allCompanies,
});

export const fetchAllCompaniesFailure = (error) => ({
	type: CompanyActionTypes.FETCH_ALL_COMPANIES_FAILURE,
	payload: error,
});

export const fetchCurrentUserCompanyStart = (username) => ({
	type: CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_START,
	payload: username,
});

export const fetchCurrentUserCompanySuccess = (company) => ({
	type: CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_SUCCESS,
	payload: company,
});

export const fetchCurrentUserCompanyFailure = (error) => ({
	type: CompanyActionTypes.FETCH_CURRENT_USER_COMPANY_FAILURE,
	payload: error,
});

export const updateCompanyCodeStart = () => ({
	type: CompanyActionTypes.UPDATE_COMPANY_CODE_START,
});

export const updateCompanyCodeSuccess = () => ({
	type: CompanyActionTypes.UPDATE_COMPANY_CODE_SUCCESS,
});

export const updateCompanyCodeFailure = (error) => ({
	type: CompanyActionTypes.UPDATE_COMPANY_CODE_FAILURE,
	payload: error,
});

export const updateCompanyStart = (companyInfo) => ({
	type: CompanyActionTypes.UPDATE_COMPANY_START,
	payload: companyInfo,
});

export const updateCompanySuccess = () => ({
	type: CompanyActionTypes.UPDATE_COMPANY_SUCCESS,
});

export const updateCompanyFailure = (error) => ({
	type: CompanyActionTypes.UPDATE_COMPANY_FAILURE,
	payload: error,
});

export const clearCompanyError = () => ({
	type: CompanyActionTypes.CLEAR_ERROR,
});
