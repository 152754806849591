const JoinRequestActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// FETCH JOIN_REQUESTS
	FETCH_JOIN_REQUESTS_START: "FETCH_JOIN_REQUESTS_START",
	FETCH_JOIN_REQUESTS_SUCCESS: "FETCH_JOIN_REQUESTS_SUCCESS",
	FETCH_JOIN_REQUESTS_FAILURE: "FETCH_JOIN_REQUESTS_FAILURE",
	// ACCEPT JOIN_REQUEST
	ACCEPT_JOIN_REQUEST_START: "ACCEPT_JOIN_REQUEST_START",
	ACCEPT_JOIN_REQUEST_SUCCESS: "ACCEPT_JOIN_REQUEST_SUCCESS",
	ACCEPT_JOIN_REQUEST_FAILURE: "ACCEPT_JOIN_REQUEST_FAILURE",
	// REJECT JOIN_REQUEST
	REJECT_JOIN_REQUEST_START: "REJECT_JOIN_REQUEST_START",
	REJECT_JOIN_REQUEST_SUCCESS: "REJECT_JOIN_REQUEST_SUCCESS",
	REJECT_JOIN_REQUEST_FAILURE: "REJECT_JOIN_REQUEST_FAILURE",
};

export default JoinRequestActionTypes;
