import { createSelector } from "reselect";

const selectSnackbar = (state) => state.snackbar;

export const selectSnackbarOpen = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.open
);

export const selectSnackbarMessage = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.message
);

export const selectSnackbarPlace = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.place
);

export const selectSnackbarColor = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.color
);

export const selectSnackbarTimer = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.timer
);

export const selectSnackbarOnClose = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.onClose
);

export const selectSnackbarDispatchActions = createSelector(
	[selectSnackbar],
	(snackbar) => snackbar.dispatchActions
);
