import MemberActionTypes from "./member.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	plural: [],
};

const memberReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MemberActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case MemberActionTypes.FETCH_MEMBERS_START:
		case MemberActionTypes.DELETE_MEMBER_START:
			return {
				...state,
				loading: true,
			};
		case MemberActionTypes.FETCH_MEMBERS_FAILURE:
		case MemberActionTypes.DELETE_MEMBER_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case MemberActionTypes.FETCH_MEMBERS_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		case MemberActionTypes.DELETE_MEMBER_SUCCESS: {
			return {
				...state,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default memberReducer;
