import HarvesterActionTypes from "./harvester.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	plural: [],
};

const harvesterReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HarvesterActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case HarvesterActionTypes.FETCH_HARVESTERS_START:
		case HarvesterActionTypes.INSERT_HARVESTER_START:
		case HarvesterActionTypes.UPDATE_HARVESTER_START:
		case HarvesterActionTypes.DELETE_HARVESTER_START:
		case HarvesterActionTypes.IMPORT_HARVESTERS_START:
			return {
				...state,
				loading: true,
			};
        case HarvesterActionTypes.FETCH_HARVESTERS_SUCCESS: {
            return {
                ...state,
                plural: action.payload,
                loading: false,
            };
        }
        case HarvesterActionTypes.INSERT_HARVESTER_SUCCESS:
        case HarvesterActionTypes.UPDATE_HARVESTER_SUCCESS:
        case HarvesterActionTypes.DELETE_HARVESTER_SUCCESS:
        case HarvesterActionTypes.IMPORT_HARVESTERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
		case HarvesterActionTypes.FETCH_HARVESTERS_FAILURE:
		case HarvesterActionTypes.INSERT_HARVESTER_FAILURE:
		case HarvesterActionTypes.UPDATE_HARVESTER_FAILURE:
		case HarvesterActionTypes.DELETE_HARVESTER_FAILURE:
		case HarvesterActionTypes.IMPORT_HARVESTERS_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default harvesterReducer;
