const MemberActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch MEMBERS
	FETCH_MEMBERS_START: "FETCH_MEMBERS_START",
	FETCH_MEMBERS_SUCCESS: "FETCH_MEMBERS_SUCCESS",
	FETCH_MEMBERS_FAILURE: "FETCH_MEMBERS_FAILURE",
	// DELETE MEMBERS
	DELETE_MEMBER_START: "DELETE_MEMBER_START",
	DELETE_MEMBER_SUCCESS: "DELETE_MEMBER_SUCCESS",
	DELETE_MEMBER_FAILURE: "DELETE_MEMBER_FAILURE",
};

export default MemberActionTypes;
