import MutuAncakActivityActionTypes from "./mutu-ancak-activity.types";

const INITIAL_STATE = {
	// general
	details: [],
	error: null,
	loading: false,
	plural: [],
	singular: null,
};

const mutuAncakActivityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MutuAncakActivityActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_START:
		case MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_START:
		case MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_START:
			return {
				...state,
				loading: true,
			};
		case MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_FAILURE:
		case MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case MutuAncakActivityActionTypes.FETCH_MUTU_ANCAK_ACTIVITIES_SUCCESS: {
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		case MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_SUCCESS: {
			return {
				...state,
				singular: action.payload,
				loading: false,
			};
		}
		case MutuAncakActivityActionTypes.FETCH_CURRENT_MUTU_ANCAK_DETAILS_SUCCESS: {
			return {
				...state,
				details: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default mutuAncakActivityReducer;
