import HarvestActivityActionTypes from "./harvest-activity.types";

export const fetchHarvestActivitiesStart = (filters) => ({
	type: HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_START,
	payload: filters,
});

export const fetchHarvestActivitiesSuccess = (members) => ({
	type: HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_SUCCESS,
	payload: members,
});

export const fetchHarvestActivitiesFailure = (error) => ({
	type: HarvestActivityActionTypes.FETCH_HARVEST_ACTIVITIES_FAILURE,
	payload: error,
});

export const clearHarvestActivityError = () => ({
	type: HarvestActivityActionTypes.CLEAR_ERROR,
});
