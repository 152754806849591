import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
	colors: {
		primary: "#2E7D32",
		secondary: "#9BDC05",
		button: "#689f39",
		cardTitle: "#f5f5f5",
		dangerButton: "#FF6961",
		infoButton: "#5bc0de",
	},
});
