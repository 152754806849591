import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearMemberError,
	deleteMemberFailure,
	deleteMemberSuccess,
	fetchMembersFailure,
	fetchMembersStart,
	fetchMembersSuccess,
} from "./member.action";
import MemberActionTypes from "./member.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchMembers({ payload }) {
	const companyId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/users?fltCompany=${companyId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const members = response.data.records;
		yield put(fetchMembersSuccess(members));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMemberError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchMembersFailure(errorMessage));
	}
}

export function* onFetchMembersStart() {
	yield takeLatest(MemberActionTypes.FETCH_MEMBERS_START, fetchMembers);
}

export function* deleteMember({ payload }) {
	const userId = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const data = queryString.stringify({ userId });

	try {
		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/users/${currentUser.username}/delete-member-company`,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			data: data,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteMemberSuccess());
		yield put(fetchMembersStart(company.id));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMemberError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteMemberFailure(errorMessage));
	}
}

export function* onDeleteMemberStart() {
	yield takeLatest(MemberActionTypes.DELETE_MEMBER_START, deleteMember);
}

export function* memberSagas() {
	yield all([call(onFetchMembersStart), call(onDeleteMemberStart)]);
}
