import TaksasiVsPanenActivityActionTypes from "./taksasiVsPanen-activity.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	plural: [],
};

const taksasiVsPanenActivityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TaksasiVsPanenActivityActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_START:
			return {
				...state,
				loading: true,
			};
		case TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case TaksasiVsPanenActivityActionTypes.FETCH_TAKSASI_VS_PANEN_SUCCESS:
		{
			return {
				...state,
				plural: action.payload,
				loading: false,
			};
		}
		default:
			return state;
	}
};

export default taksasiVsPanenActivityReducer;
